/* ActiveUserTable.css */

.table thead th {
  background-color: #6c757d; /* Gray background color for the header */
  color: #ffffff; /* White text color for the header */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px; /* Optional box shadow */


}

.table tbody tr:hover {
  background-color: #f8f9fa; /* Light gray background color on row hover */
}

.table tbody tr {
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.cont {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: center;
}

.toggle {
  position: relative;
  display: inline-block;
}

.toggle__input {
  display: none;
}

.toggle__label {
  display: block;
  width: 60px;
  height: 30px;
  background-color: gray; /* Default color for unchecked state */
  border-radius: 99px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle__input:checked + .toggle__label {
  background-color: green; /* Color when the toggle is checked */
}

.toggle__label::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background-color: white; /* Circle color */
  border-radius: 50%;
  transition: left 0.3s ease;
}

.toggle__input:checked + .toggle__label::after {
  left: 32px;
}

.btn-delete {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.btn-delete:hover {
  opacity: 0.7;
}

.table-container {
  max-height: 700px; /* Adjust this to your desired table height */
  overflow-y: auto;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
  margin-top: 1rem;
}

.table thead th {
  position: sticky;
  top: 0;
  background-color: #4f4c4c; /* Background for header */
  z-index: 1;
  box-shadow: 0 2px 2px -1px rgba(196, 190, 190, 0.4); /* Shadow under header */
  border-bottom: 2px solid #ddd;
}

.table tbody tr:hover {
  background-color: #f5f5f5; /* Optional: Add a hover effect */
}
