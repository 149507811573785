@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  /* background-color: "#abbdcf"; */
}

.addbutton {
  outline: none;
  border: none;
  background: gray;
  color: white;
  padding: 6px 10px;
  border-radius: 10px;
  transition: background 0.3s ease, transform 0.2s ease;
}

.addbutton:hover {
  background: darkgray; /* Change the background color on hover */
  transform: scale(1.05); /* Slightly increase the size on hover */
}
